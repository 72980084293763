
import { Vue, Component, Watch } from 'vue-property-decorator'
import * as constantsData from '@/app/infrastructures/misc/Constants/updateAppVersion'
import controller from '@/app/ui/controllers/UpdateAppVersionController'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { UpdateAppVersion } from '@/domain/entities/UpdateAppVersion'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { EnumUpdateAppVersionStatus } from '@/app/infrastructures/misc/Constants/updateAppVersion';

@Component({
  components: {
    Button,
    Badge,
    LoadingOverlay,
  },
})
export default class UpdateAppVersionDetail extends Vue {
  controller = controller
  constantsData = constantsData
  idTitle = ''
  statusBadge = {
    typeBadge: 'success',
    status: constantsData.EnumUpdateAppVersionStatus.PUBLISHED,
  }

  form: {
    operationSystem: string
    updateType: string
    appVersion: string
    versionValidation: string
    publishDate: string
    publishTime: string
  } = {
    operationSystem: '',
    updateType: '',
    appVersion: '',
    versionValidation: '',
    publishDate: '',
    publishTime: '',
  }

  created(): void {
    controller.getOne(+this.$route.params.id)
  }

  @Watch('controller.detailData')
  onControllerDetailDataChange(value: {
      id: number
      os: string
      forceVersion: string
      recommendVersion: string
      publishDateTime: string
      status: string
      versionValidation: string
    } | null
  ): void {
    if (value) {
      this.idTitle = `- ID ${value.id}`
      this.form.operationSystem = Utils.toCapitalize(value.os)
      this.form.updateType = Utils.toCapitalize(
        value.forceVersion !== '1.0.0'
          ? constantsData.EnumUpdateAppVersion.FORCE
          : constantsData.EnumUpdateAppVersion.RECOMMEND
      )
      this.form.versionValidation = value.versionValidation
      this.form.appVersion =
        value.forceVersion !== '1.0.0'
          ? value.forceVersion
          : value.recommendVersion
      this.form.publishDate = Utils.formatDate(
        value.publishDateTime,
        'DD MMMM YYYY'
      )
      this.form.publishTime = Utils.formatDate(
        value.publishDateTime,
        'hh:mm:ss'
      )
      this.statusBadge = this.getStatus(value.status)
    }
  }

  getStatus(status: string): { typeBadge: string; status: constantsData.EnumUpdateAppVersionStatus } {
    const currentStatus = Utils.toCapitalize(status)
    if (currentStatus === EnumUpdateAppVersionStatus.CANCELLED) {
      return {
        typeBadge: 'error-1',
        status: constantsData.EnumUpdateAppVersionStatus.CANCELLED,
      }
    }

    if (currentStatus === EnumUpdateAppVersionStatus.PENDING) {
      return {
        typeBadge: 'warning',
        status: constantsData.EnumUpdateAppVersionStatus.PENDING,
      }
    }
    return {
      typeBadge: 'success',
      status: constantsData.EnumUpdateAppVersionStatus.PUBLISHED,
    }
  }

  beforeDestroy(): void {
    controller.setDetailData(null)
  }
}
